var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state && _vm.session && _vm.decision)?_c('span',[_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-text',[_c('span',{staticClass:"d-flex flex-row flex-wrap align-center justify-start"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.studies.purchaseMarketStudy
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.studies.purchaseMarketStudy\n          "}]},[_c('span',{staticClass:"pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"},[_c('v-switch',{staticClass:"pr-1",attrs:{"disabled":_vm.disabled,"color":"primary"},on:{"change":_vm.onDecisionUpdate},model:{value:(_vm.decision.studies.purchaseMarketResearch),callback:function ($$v) {_vm.$set(_vm.decision.studies, "purchaseMarketResearch", $$v)},expression:"decision.studies.purchaseMarketResearch"}}),_c('b',{domProps:{"textContent":_vm._s('Étude de marché')}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                  'Étude vous permettant d\'accéder aux informations commerciales de vos concurrents sur les différentes zones disponibles (part de marché, valeur des ventes, quantité des ventes, budgets, etc.)'
                )}})]),_c('b',{staticClass:"pr-8",domProps:{"textContent":_vm._s(
                '(' +
                _vm.dataFormater.formatMoney(
                  parseFloat(_vm.session.mainParameters.studies.marketStudyPrice)
                ) +
                ')'
              )}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.studies.purchaseHrStudy
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.studies.purchaseHrStudy\n          "}]},[_c('span',{staticClass:"pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"},[_c('v-switch',{staticClass:"pr-1",attrs:{"disabled":_vm.disabled,"color":"primary"},on:{"change":_vm.onDecisionUpdate},model:{value:(_vm.decision.studies.purchaseHrResearch),callback:function ($$v) {_vm.$set(_vm.decision.studies, "purchaseHrResearch", $$v)},expression:"decision.studies.purchaseHrResearch"}}),_c('b',{domProps:{"textContent":_vm._s('Étude de ressources humaines')}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                  'Étude vous permettant d\'accéder à des informations sur les ressources humaines de vos concurrents (effectifs, budget social, salaires, commissions, ...)'
                )}})]),_c('b',{staticClass:"pr-8",domProps:{"textContent":_vm._s(
                '(' +
                _vm.dataFormater.formatMoney(
                  parseFloat(_vm.session.mainParameters.studies.hrStudyPrice)
                ) +
                ')'
              )}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.studies.purchaseAttractivityStudy
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.studies.purchaseAttractivityStudy\n          "}]},[_c('span',{staticClass:"pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"},[_c('v-switch',{staticClass:"pr-1",attrs:{"disabled":_vm.disabled,"color":"primary"},on:{"change":_vm.onDecisionUpdate},model:{value:(_vm.decision.studies.purchaseAttractivityResearch),callback:function ($$v) {_vm.$set(_vm.decision.studies, "purchaseAttractivityResearch", $$v)},expression:"decision.studies.purchaseAttractivityResearch"}}),_c('b',{domProps:{"textContent":_vm._s('Étude d\'attractivité')}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                  'Étude vous permettant d\'accéder pour chacun de vos produits/services aux informations d\'attractivité (prix, qualité, force de vente, marketing, communication)'
                )}})]),_c('b',{staticClass:"pr-8",domProps:{"textContent":_vm._s(
                '(' +
                _vm.dataFormater.formatMoney(
                  parseFloat(
                    _vm.session.mainParameters.studies.attractivityStudyPrice
                  )
                ) +
                ')'
              )}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.studies.purchaseRseStudy
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.studies.purchaseRseStudy\n          "}]},[_c('span',{staticClass:"pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"},[_c('v-switch',{staticClass:"pr-1",attrs:{"disabled":_vm.disabled,"color":"primary"},on:{"change":_vm.onDecisionUpdate},model:{value:(_vm.decision.studies.purchaseRseResearch),callback:function ($$v) {_vm.$set(_vm.decision.studies, "purchaseRseResearch", $$v)},expression:"decision.studies.purchaseRseResearch"}}),_c('b',{domProps:{"textContent":_vm._s('Étude RSE')}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                  'Étude vous permettant d\'accéder a votres score RSE (sociale, energie, Empreinte carbone, ...)'
                )}})]),_c('b',{staticClass:"pr-8",domProps:{"textContent":_vm._s(
                '(' +
                _vm.dataFormater.formatMoney(
                  parseFloat(_vm.session.mainParameters.studies.rseStudyPrice)
                ) +
                ')'
              )}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.profile.role != 'User' ||
            _vm.session.decisionParameters.studies.purchaseCostStudy
          ),expression:"\n            profile.role != 'User' ||\n            session.decisionParameters.studies.purchaseCostStudy\n          "}]},[_c('span',{staticClass:"pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"},[_c('v-switch',{staticClass:"pr-1",attrs:{"disabled":_vm.disabled,"color":"primary"},on:{"change":_vm.onDecisionUpdate},model:{value:(_vm.decision.studies.purchaseCostResearch),callback:function ($$v) {_vm.$set(_vm.decision.studies, "purchaseCostResearch", $$v)},expression:"decision.studies.purchaseCostResearch"}}),_c('b',{domProps:{"textContent":_vm._s('Étude de coûts')}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"600","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,false,393181115)},[_c('span',{domProps:{"textContent":_vm._s(
                  'Étude vous permettant d\'accéder pour chacun de vos produits/services aux informations de coûts (coût d\'achat, coût de production, coût de vente, ...)'
                )}})]),_c('b',{staticClass:"pr-8",domProps:{"textContent":_vm._s(
                '(' +
                _vm.dataFormater.formatMoney(
                  parseFloat(_vm.session.mainParameters.studies.costStudyPrice)
                ) +
                ')'
              )}})],1)])]),_c('v-divider'),_c('span',{staticClass:"pt-5 pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Sous total achats études : ")]),_c('span',{domProps:{"textContent":_vm._s(_vm.dataFormater.formatMoney(_vm.totalPrice))}})])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }