import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "initialStates"

class InitialState{
    constructor(id, session, finances, machines, hr, production){
        this.id = id
        this.session = session
        this.finances = finances
        this.machines = machines
        this.hr = hr
        this.production = production        
    }

    static async initializeOne(sessionId){
        let tmp_finances = {
            capital: 0,
            netSituation: 0,
            treasury: 0,
            loanCapital: 0,
            taxCredits: [],
            workingCapital: 0,
            workingCapitalNeeds: 0,
            contractedLoans: [],
        }

        let tmp_machines = {
            currentMachines: [],
            unlockedMachines: [],
        }

        let tmp_hr = {
            employeeAmount: 0,
            workerAmount: 0,
            currentCvs: [],
            firedCvs: [],
        }

        let tmp_production = {
            unlockedProducts : [],
            unlockedServices : [],
            stocks : {},
        }

        let tmp_InitialState = new InitialState(null, sessionId, tmp_finances, tmp_machines, tmp_hr, tmp_production)
        return tmp_InitialState
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let initialStates = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_initialState = new InitialState(doument.id, data.session, data.finances, data.machines, data.hr, data.production)
            initialStates.push(tmp_initialState)
        })

        return initialStates
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let initialState = new InitialState(response.id, data.session, data.finances, data.machines, data.hr, data.production)

        return initialState
    }

    static async getBySession(sessionId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("session", "==", sessionId))
        let response = await getDocs(documentQuery)

        let initialStates = []

        response.forEach(document => {
            let data = document.data()
            let tmp_initialState = new InitialState(document.id, data.session, data.finances, data.machines, data.hr, data.production)
            initialStates.push(tmp_initialState)
        })

        return initialStates
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let initialStates = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_initialState = new InitialState(document.id, data.session, data.finances, data.machines, data.hr, data.production)
                initialStates.push(tmp_initialState)
            })
            
            if(callback != null){
                callback(initialStates)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let initialState = new InitialState(snapshot.id, data.session, data.finances, data.machines, data.hr, data.production)
            
            if(callback != null){
                callback(initialState)
            }
        })
        return unsub
    }

    static async listenBySession(sessionId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("session", "==", sessionId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let initialStates = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_initialState =  new InitialState(document.id, data.session, data.finances, data.machines, data.hr, data.production)
                initialStates.push(tmp_initialState)
            })
            
            if(callback != null){
                callback(initialStates)
            }
        })
        return unsub
    } 

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                session : this.session,
                finances : this.finances,
                machines : this.machines,
                hr : this.hr,
                production : this.production,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                session : this.session,
                finances : this.finances,
                machines : this.machines,
                hr : this.hr,
                production : this.production,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default InitialState