<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-card-title primary-title> Prévisions financières : </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.forecast.result
            "
          >
            <v-text-field
              :disabled="disabled"
              label="Résultat net"
              suffix="€"
              v-model="decision.forecast.results"
              :error="errorRules.results"
              @change="onDecisionUpdate"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.forecast.treasury
            "
          >
            <v-text-field
              :disabled="disabled"
              label="Trésorerie"
              suffix="€"
              v-model="decision.forecast.treasury"
              :error="errorRules.treasury"
              @change="onDecisionUpdate"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.forecast.turnover
            "
          >
            <v-text-field
              :disabled="disabled"
              label="Chiffre d'affaires"
              suffix="€"
              v-model="decision.forecast.turnover"
              :error="errorRules.turnover"
              @change="onDecisionUpdate"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
export default {
  name: "DecisionForecast",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  data() {
    return {
      errorMessages: [],
      errorRules: {
        results: false,
        treasury: false,
        turnover: false,
      },
    };
  },
  mounted() {
    this.checkErrors();
  },
  methods: {
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        results: false,
        treasury: false,
        turnover: false,
      };
      let errorStatus = false;

      if (isNaN(parseFloat(this.decision.forecast.results))) {
        tmp_errorMessages.push("La prévision du résultat doit être un nombre");
        errorStatus = true;
        tmp_rules.results = true;
      }

      if (isNaN(parseFloat(this.decision.forecast.treasury))) {
        tmp_errorMessages.push(
          "La prévision de la trésorerie doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.treasury = true;
      }

      if (isNaN(parseFloat(this.decision.forecast.turnover))) {
        tmp_errorMessages.push(
          "La prévision du chiffre d'affaires doit être un nombre"
        );
        errorStatus = true;
        tmp_rules.turnover = true;
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
