var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.state && _vm.session && _vm.decision)?_c('span',[(_vm.errorMessages.length > 0)?_c('v-alert',{attrs:{"border":"left","colored-border":"","color":"error","elevation":"2"}},[_c('h3',{staticClass:"ml-5 mb-3 error--text"},[_vm._v(" Cette page comporte des données manquantes ou éronées ")]),_c('ul',{staticClass:"ml-5"},_vm._l((_vm.errorMessages),function(message){return _c('li',{staticClass:"py-2 error--text",domProps:{"textContent":_vm._s(message)}})}),0)]):_vm._e(),_c('v-card',{staticClass:"mb-12",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Récapitulatif : ")]),_c('v-card-text',[_c('span',{staticClass:"d-flex flex-row flex-wrap align-center justify-start"},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',{staticClass:"pr-1"},[_vm._v("Frais d'acquisition :")]),_c('span',{domProps:{"textContent":_vm._s(_vm.dataFormater.formatMoney(parseFloat(_vm.totalBuyingCost)))}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',{staticClass:"pr-1"},[_vm._v("Frais de Recherche & Développement :")]),_c('span',{domProps:{"textContent":_vm._s(
              _vm.dataFormater.formatMoney(parseFloat(_vm.totalResearchingCost))
            )}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.profile.role != 'User' ||
          _vm.session.decisionParameters.budget.maintenance
        ),expression:"\n          profile.role != 'User' ||\n          session.decisionParameters.budget.maintenance\n        "}],staticClass:"ma-0 pa-2"},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Budget maintenance","suffix":"€","error":_vm.errorRules.maintenanceBudget},on:{"change":_vm.onDecisionUpdate},model:{value:(_vm.decision.machines.maintenanceBudget),callback:function ($$v) {_vm.$set(_vm.decision.machines, "maintenanceBudget", $$v)},expression:"decision.machines.maintenanceBudget"}})],1)])],1),_c('div',{staticClass:"mb-12 pa-0"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"pr-3"},[_vm._v("Mes machines :")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.profile.role != 'User' ||
          _vm.session.decisionParameters.machine.purchase
        ),expression:"\n          profile.role != 'User' ||\n          session.decisionParameters.machine.purchase\n        "}],attrs:{"disabled":_vm.disabled,"color":"primary","outlined":""},on:{"click":function($event){_vm.infraDialog = true}}},[_vm._v("Voir le catalogue")])],1),_c('v-row',[_vm._l((_vm.decision.machines.buying),function(machine){return _c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center",attrs:{"primary-title":""}},[_c('span',{staticClass:"pb-3",domProps:{"textContent":_vm._s(machine.name)}})]),_c('v-card-text',[_c('v-img',{attrs:{"contain":"","height":"auto","max-height":"150px","src":require(`@/assets/images/icons/machines/Machine${machine.icon}.svg`)}}),_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-center"},[_c('b',[_vm._v("En cours d'acquisition")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-center"},[_c('b',[_vm._v("Livraison : ")]),(machine.delivery > 0)?_c('span',{domProps:{"textContent":_vm._s('dans ' + machine.delivery + ' periode(s)')}}):_c('span',{domProps:{"textContent":_vm._s('instantanée')}})])])],1),_c('v-divider'),_c('span',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"accent","outlined":""},on:{"click":function($event){return _vm.cancelBuying(machine)}}},[_vm._v("Annuler")])],1)],1)],1)],1)}),_vm._l((_vm.state.machines.currentMachines),function(machine){return _c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center",attrs:{"primary-title":""}},[_c('span',{staticClass:"pb-3",domProps:{"textContent":_vm._s(
                machine.machine.name + ' ( ' + machine.uuid.slice(-8) + ' )'
              )}})]),_c('v-card-text',[_c('v-img',{attrs:{"contain":"","height":"auto","max-height":"150px","src":require(`@/assets/images/icons/machines/Machine${machine.machine.icon}.svg`)}}),_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-center"},[_c('b',[_vm._v("Période(s) d'utilisation : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.age)}})])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-center"},[_c('b',[_vm._v("Valeur à la revente : ")]),_c('span',{domProps:{"textContent":_vm._s(
                      _vm.dataFormater.formatMoney(
                        machine.value *
                          (1 -
                            parseFloat(
                              _vm.session.machinesConfig[machine.machine.uuid]
                                .saleLoss
                            ) /
                              100)
                      )
                    )}})])])],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.profile.role != 'User' ||
                _vm.session.decisionParameters.machine.sell
              ),expression:"\n                profile.role != 'User' ||\n                session.decisionParameters.machine.sell\n              "}]}),_c('span',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[(!_vm.decision.machines.selling.includes(machine.uuid))?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.profile.role != 'User' ||
                  _vm.session.decisionParameters.machine.sell
                ),expression:"\n                  profile.role != 'User' ||\n                  session.decisionParameters.machine.sell\n                "}],attrs:{"disabled":_vm.disabled,"color":"accent","outlined":""},on:{"click":function($event){return _vm.sellMachine(machine)}}},[_vm._v("Vendre")]):_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.profile.role != 'User' ||
                  _vm.session.decisionParameters.machine.sell
                ),expression:"\n                  profile.role != 'User' ||\n                  session.decisionParameters.machine.sell\n                "}],attrs:{"disabled":_vm.disabled,"color":"secondary","outlined":""},on:{"click":function($event){return _vm.cancelSelling(machine)}}},[_vm._v("Annuler la vente")])],1)],1)],1)],1)})],2),_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.infraDialog),callback:function ($$v) {_vm.infraDialog=$$v},expression:"infraDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-space-between",attrs:{"primary-title":""}},[_c('span',[_vm._v("Acheter des machines")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.infraDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',_vm._l((Object.values(
                _vm.session.machinesConfig
              ).sort((a, b) =>
                parseFloat(a.grossValue) + parseFloat(a.rndCost) >
                parseFloat(b.grossValue) + parseFloat(b.rndCost)
                  ? 1
                  : -1
              )),function(machine,index){return _c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"flex-column justify-center",attrs:{"primary-title":""}},[_c('h4',{domProps:{"textContent":_vm._s(machine.name)}}),(!machine.available)?_c('h5',[_vm._v("(En rupture de stock)")]):(
                      _vm.state.machines.unlockedMachines.includes(
                        machine.uuid
                      ) || machine.rndCost <= 0
                    )?_c('h5',[_vm._v(" (Disponible) ")]):(
                      _vm.decision.machines.researching.includes(machine.uuid)
                    )?_c('h5',[_vm._v(" (Recherche en cours) ")]):_c('h5',[_vm._v("(Recherche nécessaire)")])]),_c('v-card-text',[_c('v-img',{attrs:{"contain":"","height":"auto","max-height":"150px","src":require(`@/assets/images/icons/machines/Machine${machine.icon}.svg`)}}),_c('div',{staticClass:"pb-5"},[_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Temps de livraison : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.delivery + ' periode(s)')}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Nombre de postes de travail : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.workplaceAmount)}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Modificateur de productivité : ")]),_c('span',{domProps:{"textContent":_vm._s(
                          (machine.productionBoost >= 0 ? '+' : '') +
                          machine.productionBoost +
                          ' %'
                        )}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Frais de structures : ")]),_c('span',{domProps:{"textContent":_vm._s(
                          _vm.dataFormater.formatMoney(
                            parseFloat(machine.structuralCost)
                          )
                        )}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Budget maintenance optimal : ")]),_c('span',{domProps:{"textContent":_vm._s(
                          _vm.dataFormater.formatMoney(
                            parseFloat(machine.optimalMaintenanceBudget)
                          )
                        )}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Durée d'amortissement : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.deprecationPeriods + ' periode(s)')}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Consomation éléctrique : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.conso + ' W/Production')}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Emissions de CO2 : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.carbonEmission + ' g/production')}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Perte à la revente : ")]),_c('span',{domProps:{"textContent":_vm._s(machine.saleLoss + ' %')}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Coût R&D : ")]),_c('span',{domProps:{"textContent":_vm._s(
                          _vm.dataFormater.formatMoney(
                            parseFloat(machine.rndCost)
                          )
                        )}})]),_c('span',{staticClass:"pa-2 d-flex flex-row flex-wrap align-center justify-start"},[_c('b',[_vm._v("Peut produire : ")]),_vm._l((Object.keys(
                          _vm.session.productionParameters[machine.uuid].products
                        )),function(productId){return (
                          _vm.session.productionParameters[machine.uuid].products[
                            productId
                          ]
                        )?_c('span',{staticClass:"pr-2",domProps:{"textContent":_vm._s(_vm.session.productsConfig[productId].name)}}):_vm._e()}),_vm._l((Object.keys(
                          _vm.session.productionParameters[machine.uuid].services
                        )),function(serviceId){return (
                          _vm.session.productionParameters[machine.uuid].services[
                            serviceId
                          ]
                        )?_c('span',{staticClass:"pr-2",domProps:{"textContent":_vm._s(_vm.session.servicesConfig[serviceId].name)}}):_vm._e()})],2)]),_c('v-divider'),_c('span',{staticClass:"mt-5 d-flex flex-row align-center justify-center"},[(
                        (_vm.state.machines.unlockedMachines.includes(
                          machine.uuid
                        ) ||
                          machine.rndCost <= 0) &&
                        machine.available
                      )?_c('span',[_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"primary","outlined":""},domProps:{"textContent":_vm._s(
                          'Acheter (' +
                          _vm.dataFormater.formatMoney(
                            parseFloat(machine.grossValue)
                          ) +
                          ')'
                        )},on:{"click":function($event){return _vm.buy(machine)}}})],1):(
                        _vm.state.machines.unlockedMachines.includes(
                          machine.uuid
                        ) || machine.rndCost <= 0
                      )?_c('span',[_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"secondary","disabled":"","outlined":""},domProps:{"textContent":_vm._s('En rupture de stock')}})],1):(
                        _vm.decision.machines.researching.includes(machine.uuid)
                      )?_c('span',[_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"secondary","outlined":""},domProps:{"textContent":_vm._s('Annuler la recherche')},on:{"click":function($event){return _vm.cancelResearching(machine)}}})],1):_c('span',[_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"accent","outlined":""},domProps:{"textContent":_vm._s(
                          'Rechercher (' +
                          _vm.dataFormater.formatMoney(
                            parseFloat(machine.rndCost)
                          ) +
                          ')'
                        )},on:{"click":function($event){return _vm.research(machine)}}})],1)])],1)],1)],1)}),1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }