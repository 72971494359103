<template>
  <span v-if="state && session && decision">
    <v-card class="mb-12" outlined>
      <v-card-text>
        <span class="d-flex flex-row flex-wrap align-center justify-start">
          <div
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.studies.purchaseMarketStudy
            "
          >
            <span
              class="pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"
            >
              <v-switch
                :disabled="disabled"
                class="pr-1"
                color="primary"
                v-model="decision.studies.purchaseMarketResearch"
                @change="onDecisionUpdate"
              ></v-switch>
              <b v-text="'Étude de marché'"></b>
              <v-tooltip bottom max-width="600" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-help</v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    'Étude vous permettant d\'accéder aux informations commerciales de vos concurrents sur les différentes zones disponibles (part de marché, valeur des ventes, quantité des ventes, budgets, etc.)'
                  "
                ></span>
              </v-tooltip>
              <b
                class="pr-8"
                v-text="
                  '(' +
                  dataFormater.formatMoney(
                    parseFloat(session.mainParameters.studies.marketStudyPrice)
                  ) +
                  ')'
                "
              ></b>
            </span>
          </div>

          <div
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.studies.purchaseHrStudy
            "
          >
            <span
              class="pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"
            >
              <v-switch
                :disabled="disabled"
                class="pr-1"
                color="primary"
                v-model="decision.studies.purchaseHrResearch"
                @change="onDecisionUpdate"
              ></v-switch>
              <b v-text="'Étude de ressources humaines'"></b>
              <v-tooltip bottom max-width="600" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-help</v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    'Étude vous permettant d\'accéder à des informations sur les ressources humaines de vos concurrents (effectifs, budget social, salaires, commissions, ...)'
                  "
                ></span>
              </v-tooltip>
              <b
                class="pr-8"
                v-text="
                  '(' +
                  dataFormater.formatMoney(
                    parseFloat(session.mainParameters.studies.hrStudyPrice)
                  ) +
                  ')'
                "
              ></b>
            </span>
          </div>

          <div
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.studies.purchaseAttractivityStudy
            "
          >
            <span
              class="pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"
            >
              <v-switch
                :disabled="disabled"
                class="pr-1"
                color="primary"
                v-model="decision.studies.purchaseAttractivityResearch"
                @change="onDecisionUpdate"
              ></v-switch>
              <b v-text="'Étude d\'attractivité'"></b>
              <v-tooltip bottom max-width="600" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-help</v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    'Étude vous permettant d\'accéder pour chacun de vos produits/services aux informations d\'attractivité (prix, qualité, force de vente, marketing, communication)'
                  "
                ></span>
              </v-tooltip>
              <b
                class="pr-8"
                v-text="
                  '(' +
                  dataFormater.formatMoney(
                    parseFloat(
                      session.mainParameters.studies.attractivityStudyPrice
                    )
                  ) +
                  ')'
                "
              ></b>
            </span>
          </div>

          <div
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.studies.purchaseRseStudy
            "
          >
            <span
              class="pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"
            >
              <v-switch
                :disabled="disabled"
                class="pr-1"
                color="primary"
                v-model="decision.studies.purchaseRseResearch"
                @change="onDecisionUpdate"
              ></v-switch>
              <b v-text="'Étude RSE'"></b>
              <v-tooltip bottom max-width="600" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-help</v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    'Étude vous permettant d\'accéder a votres score RSE (sociale, energie, Empreinte carbone, ...)'
                  "
                ></span>
              </v-tooltip>
              <b
                class="pr-8"
                v-text="
                  '(' +
                  dataFormater.formatMoney(
                    parseFloat(session.mainParameters.studies.rseStudyPrice)
                  ) +
                  ')'
                "
              ></b>
            </span>
          </div>

          <div
            v-show="
              profile.role != 'User' ||
              session.decisionParameters.studies.purchaseCostStudy
            "
          >
            <span
              class="pa-2 pb-3 d-flex flex-row flex-wrap align-center justify-start"
            >
              <v-switch
                :disabled="disabled"
                class="pr-1"
                color="primary"
                v-model="decision.studies.purchaseCostResearch"
                @change="onDecisionUpdate"
              ></v-switch>
              <b v-text="'Étude de coûts'"></b>
              <v-tooltip bottom max-width="600" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-help</v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    'Étude vous permettant d\'accéder pour chacun de vos produits/services aux informations de coûts (coût d\'achat, coût de production, coût de vente, ...)'
                  "
                ></span>
              </v-tooltip>
              <b
                class="pr-8"
                v-text="
                  '(' +
                  dataFormater.formatMoney(
                    parseFloat(session.mainParameters.studies.costStudyPrice)
                  ) +
                  ')'
                "
              ></b>
            </span>
          </div>
        </span>
        <v-divider></v-divider>
        <span
          class="pt-5 pa-2 d-flex flex-row flex-wrap align-center justify-start"
        >
          <b>Sous total achats études :&nbsp;</b>
          <span v-text="dataFormater.formatMoney(totalPrice)"></span>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "DecisionStudies",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  setup() {
    return {
      dataFormater,
    };
  },
  computed: {
    totalPrice() {
      let price = 0;
      if (this.decision.studies.purchaseMarketResearch) {
        price += parseFloat(
          this.session.mainParameters.studies.marketStudyPrice
        );
      }
      if (this.decision.studies.purchaseHrResearch) {
        price += parseFloat(this.session.mainParameters.studies.hrStudyPrice);
      }
      if (this.decision.studies.purchaseAttractivityResearch) {
        price += parseFloat(
          this.session.mainParameters.studies.attractivityStudyPrice
        );
      }
      if (this.decision.studies.purchaseRseResearch) {
        price += parseFloat(this.session.mainParameters.studies.rseStudyPrice);
      }

      if (this.decision.studies.purchaseCostResearch) {
        price += parseFloat(this.session.mainParameters.studies.costStudyPrice);
      }
      return price;
    },
  },
  mounted() {
    this.checkErrors();
  },
  methods: {
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    checkErrors() {
      return false;
    },
  },
};
</script>
