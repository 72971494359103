<template>
  <span v-if="state && session && decision">
    <v-alert
      border="left"
      colored-border
      color="error"
      elevation="2"
      v-if="errorMessages.length > 0"
    >
      <h3 class="ml-5 mb-3 error--text">
        Cette page comporte des données manquantes ou éronées
      </h3>
      <ul class="ml-5">
        <li
          class="py-2 error--text"
          v-for="message in errorMessages"
          v-text="message"
        ></li>
      </ul>
    </v-alert>
    <v-card class="mb-12" outlined>
      <v-card-title primary-title> Récapitulatif : </v-card-title>
      <v-card-text>
        <span class="d-flex flex-row flex-wrap align-center justify-start">
          <span
            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
          >
            <b class="pr-1">Coût Recherche &amp; Développement total :</b>
            <span
              v-text="dataFormater.formatMoney(parseFloat(totalRndCost))"
            ></span>
          </span>
        </span>
      </v-card-text>
    </v-card>
    <div class="mb-12 pa-0">
      <v-row>
        <v-expansion-panels v-model="productionTypePanels" multiple>
          <v-expansion-panel
            v-if="
              Object.values(decision.production.products.production).length >
                0 ||
              Object.values(decision.production.products.researching).length > 0
            "
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="12">
                  <h2 class="py-3">Mes produits :</h2>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-for="production in Object.values(
                    decision.production.products.production
                  ).sort((a, b) =>
                    a.product.name.localeCompare(b.product.name)
                  )"
                >
                  <v-card
                    class="pa-3"
                    outlined
                    min-height="100%"
                    v-if="production.product"
                  >
                    <v-card-title primary-title>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-wrap align-center justify-start"
                        >
                          <v-avatar size="60" color="white" rounded="0">
                            <v-img
                              contain
                              height="60"
                              width="60"
                              :src="
                                require(`@/assets/images/icons/products/Product${production.product.icon}.svg`)
                              "
                            ></v-img>
                          </v-avatar>
                          <span class="d-flex align-center pl-5">
                            <span v-text="production.product.name"></span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-row class="py-5">
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Coût de base :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(production.product.productionCost)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Temps de production :&nbsp;</b>
                            <span
                              v-text="production.product.productionTime + 'h'"
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix min :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(production.product.minimalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix max :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(production.product.maximalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Stock actuel :&nbsp;</b>
                            <span
                              v-text="
                                currentStocks[production.product.uuid]
                                  ? currentStocks[production.product.uuid]
                                  : 0
                              "
                            ></span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row
                        class="mt-5 d-flex flex-row align-center justify-center"
                      >
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Production souhaitée"
                            suffix="unité(s)"
                            v-model="production.production"
                            :error="
                              errorRules.products.production[
                                production.product.uuid
                              ]
                            "
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.budget.quality
                          "
                        >
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Budget RSE et qualité"
                            suffix="€"
                            v-model="production.quality"
                            :error="
                              errorRules.products.quality[
                                production.product.uuid
                              ]
                            "
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <span class="d-flex align-center justify-center mt-5">
                        <v-btn
                          color="primary"
                          outlined
                          @click="stopProduction(production)"
                          :disabled="
                            disabled ||
                            parseInt(production.quality) +
                              parseFloat(production.production) <=
                              0
                          "
                        >
                          <v-icon class="pr-3">mdi-power</v-icon>
                          <span> Desactiver la production </span>
                        </v-btn>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-for="research in Object.values(
                    decision.production.products.researching
                  ).sort((a, b) =>
                    a.product.name.localeCompare(b.product.name)
                  )"
                >
                  <v-card
                    class="pa-3"
                    outlined
                    min-height="100%"
                    v-if="research.product"
                  >
                    <v-card-title primary-title>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-wrap align-center justify-start"
                        >
                          <v-avatar size="60" color="white" rounded="0">
                            <v-img
                              contain
                              height="60"
                              width="60"
                              :src="
                                require(`@/assets/images/icons/products/Product${research.product.icon}.svg`)
                              "
                            ></v-img>
                          </v-avatar>
                          <span class="d-flex align-center pl-5">
                            <span v-text="research.product.name"></span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-row class="py-5">
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Coût de base :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(research.product.productionCost)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Temps de production :&nbsp;</b>
                            <span
                              v-text="research.product.productionTime + 'h'"
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix min :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(research.product.minimalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix max :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(research.product.maximalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Coûts Recherche &amp; Développement :&nbsp;</b>
                            <span v-text="research.product.rndCost"></span>
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="py-0"
                          v-if="research.inResearch"
                        >
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b class="accent--text">Recherche en cours !</b>
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row
                        class="mt-5 d-flex flex-row align-center justify-center"
                      >
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Production souhaitée"
                            suffix="unité(s)"
                            value="0"
                            disabled
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.budget.quality
                          "
                        >
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Budget RSE et qualité"
                            suffix="€"
                            value="0"
                            disabled
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <span class="d-flex align-center justify-center mt-5">
                        <v-btn
                          :disabled="disabled"
                          color="accent"
                          outlined
                          v-if="research.inResearch"
                          @click="
                            research.inResearch = !research.inResearch;
                            onDecisionUpdate();
                          "
                        >
                          <v-icon class="pr-3">mdi-lock-outline</v-icon>
                          <span v-text="'Annuler la recherche'"></span>
                        </v-btn>
                        <v-btn
                          :disabled="disabled"
                          color="primary"
                          outlined
                          v-else
                          @click="
                            research.inResearch = !research.inResearch;
                            onDecisionUpdate();
                          "
                        >
                          <v-icon class="pr-3">mdi-lock-outline</v-icon>
                          <span
                            v-text="
                              'Rechercher (' +
                              dataFormater.formatMoney(
                                parseFloat(research.product.rndCost)
                              ) +
                              ')'
                            "
                          ></span>
                        </v-btn>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-if="
              Object.values(decision.production.services.production).length >
                0 ||
              Object.values(decision.production.services.researching).length > 0
            "
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="12">
                  <h2 class="py-3">Mes services :</h2>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-for="production in Object.values(
                    decision.production.services.production
                  ).sort((a, b) =>
                    a.product.name.localeCompare(b.product.name)
                  )"
                >
                  <v-card
                    class="pa-3"
                    outlined
                    min-height="100%"
                    v-if="production.service"
                  >
                    <v-card-title primary-title>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-wrap align-center justify-start"
                        >
                          <v-avatar size="60" color="white" rounded="0">
                            <v-img
                              contain
                              height="60"
                              width="60"
                              :src="
                                require(`@/assets/images/icons/services/Service${production.service.icon}.svg`)
                              "
                            ></v-img>
                          </v-avatar>
                          <span class="d-flex align-center pl-5">
                            <span v-text="production.service.name"></span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-row class="py-5">
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Coût de base :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(production.service.productionCost)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Temps de production :&nbsp;</b>
                            <span
                              v-text="production.service.productionTime + 'h'"
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix min :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(production.service.minimalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix max :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(production.service.maximalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Stock actuel :&nbsp;</b>
                            <span
                              v-text="
                                currentStocks[production.service.uuid]
                                  ? currentStocks[production.service.uuid]
                                  : 0
                              "
                            ></span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row
                        class="mt-5 d-flex flex-row align-center justify-center"
                      >
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Production max"
                            suffix="unité(s)"
                            v-model="production.production"
                            :error="
                              errorRules.services.production[
                                production.service.uuid
                              ]
                            "
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.budget.quality
                          "
                        >
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Budget RSE et qualité"
                            suffix="€"
                            v-model="production.quality"
                            :error="
                              errorRules.services.quality[
                                production.service.uuid
                              ]
                            "
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <span class="d-flex align-center justify-center mt-5">
                        <v-btn
                          color="primary"
                          outlined
                          @click="stopProduction(production)"
                          :disabled="
                            disabled ||
                            parseInt(production.quality) +
                              parseFloat(production.production) <=
                              0
                          "
                        >
                          <v-icon class="pr-3">mdi-power</v-icon>
                          <span> Desactiver la production </span>
                        </v-btn>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-for="research in Object.values(
                    decision.production.services.researching
                  ).sort((a, b) =>
                    a.product.name.localeCompare(b.product.name)
                  )"
                >
                  <v-card
                    class="pa-3"
                    outlined
                    min-height="100%"
                    v-if="research.service"
                  >
                    <v-card-title primary-title>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-wrap align-center justify-start"
                        >
                          <v-avatar size="60" color="white" rounded="0">
                            <v-img
                              contain
                              height="60"
                              width="60"
                              :src="
                                require(`@/assets/images/icons/services/Service${research.service.icon}.svg`)
                              "
                            ></v-img>
                          </v-avatar>
                          <span class="d-flex align-center pl-5">
                            <span v-text="research.service.name"></span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-row class="py-5">
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Coût de base :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(research.service.productionCost)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Temps de production :&nbsp;</b>
                            <span
                              v-text="research.service.productionTime + 'h'"
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix min :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(research.service.minimalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Prix max :&nbsp;</b>
                            <span
                              v-text="
                                dataFormater.formatMoney(
                                  parseFloat(research.service.maximalPrice)
                                )
                              "
                            ></span>
                          </span>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b>Coûts Recherche &amp; Développement :&nbsp;</b>
                            <span v-text="research.service.rndCost"></span>
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="py-0"
                          v-if="research.inResearch"
                        >
                          <span
                            class="pa-2 d-flex flex-row flex-wrap align-center justify-start"
                          >
                            <b class="accent--text">Recherche en cours !</b>
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row
                        class="mt-5 d-flex flex-row align-center justify-center"
                      >
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Production souhaitée"
                            suffix="unité(s)"
                            value="0"
                            disabled
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          v-show="
                            profile.role != 'User' ||
                            session.decisionParameters.budget.quality
                          "
                        >
                          <v-text-field
                            :disabled="disabled"
                            type="number"
                            class="mx-2 my-0"
                            label="Budget RSE et qualité"
                            suffix="€"
                            value="0"
                            disabled
                            @change="onDecisionUpdate"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <span class="d-flex align-center justify-center mt-5">
                        <v-btn
                          :disabled="disabled"
                          color="accent"
                          outlined
                          v-if="research.inResearch"
                          @click="
                            research.inResearch = !research.inResearch;
                            onDecisionUpdate();
                          "
                        >
                          <v-icon class="pr-3">mdi-lock-outline</v-icon>
                          <span v-text="'Annuler la recherche'"></span>
                        </v-btn>
                        <v-btn
                          :disabled="disabled"
                          color="primary"
                          outlined
                          v-else
                          @click="
                            research.inResearch = !research.inResearch;
                            onDecisionUpdate();
                          "
                        >
                          <v-icon class="pr-3">mdi-lock-outline</v-icon>
                          <span
                            v-text="
                              'Rechercher (' +
                              dataFormater.formatMoney(
                                parseFloat(research.service.rndCost)
                              ) +
                              ')'
                            "
                          ></span>
                        </v-btn>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "DecisionProduction",
  props: [
    "disabled",
    "profile",
    "user",
    "session",
    "state",
    "decision",
    "periods",
  ],
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      productionTypePanels: [0, 1],
      currentStocks: {},
      notUnlockedProducts: {},
      notUnlockedServices: {},
      errorMessages: [],
      errorRules: {
        products: {
          production: [],
          quality: [],
        },
        services: {
          production: [],
          quality: [],
        },
      },
    };
  },
  mounted() {
    this.preLoad();
  },
  computed: {
    totalRndCost() {
      let total = 0;
      Object.values(this.decision.production.products.researching).forEach(
        (research) => {
          if (research.inResearch) total += research.product.rndCost;
        }
      );
      Object.values(this.decision.production.services.researching).forEach(
        (research) => {
          if (research.inResearch) total += research.service.rndCost;
        }
      );
      return total;
    },
  },
  methods: {
    preLoad() {
      for (let productId in this.session.productsConfig) {
        if (
          this.session.productsConfig[productId].rndCost <= 0 ||
          this.state.production.unlockedProducts.includes(productId)
        ) {
          if (
            this.decision.production.products.production[productId] == undefined
          ) {
            let tmp_prod = {
              product: this.session.productsConfig[productId],
              production: this.session.mainParameters.production.min,
              quality: 0,
            };
            this.decision.production.products.production[productId] = tmp_prod;
          } else {
            this.decision.production.products.production[productId].product =
              this.session.productsConfig[productId];
          }
          delete this.decision.production.products.researching[productId];
        } else {
          if (
            this.decision.production.products.researching[productId] ==
            undefined
          ) {
            let tmp_research = {
              product: this.session.productsConfig[productId],
              inResearch: false,
            };
            this.decision.production.products.researching[productId] =
              tmp_research;
          } else {
            this.decision.production.products.researching[productId].product =
              this.session.productsConfig[productId];
          }
          delete this.decision.production.products.production[productId];
        }
      }

      for (let serviceId in this.session.servicesConfig) {
        if (
          this.session.servicesConfig[serviceId].rndCost <= 0 ||
          this.state.production.unlockedServices.includes(serviceId)
        ) {
          if (
            this.decision.production.services.production[serviceId] == undefined
          ) {
            let tmp_prod = {
              service: this.session.servicesConfig[serviceId],
              production: this.session.mainParameters.production.min,
              quality: 0,
            };
            this.decision.production.services.production[serviceId] = tmp_prod;
          } else {
            this.decision.production.services.production[serviceId].service =
              this.session.servicesConfig[serviceId];
          }
          delete this.decision.production.services.researching[serviceId];
        } else {
          if (
            this.decision.production.services.researching[serviceId] ==
            undefined
          ) {
            let tmp_research = {
              service: this.session.servicesConfig[serviceId],
              inResearch: false,
            };
            this.decision.production.services.researching[serviceId] =
              tmp_research;
          } else {
            this.decision.production.services.researching[serviceId].service =
              this.session.servicesConfig[serviceId];
          }
          delete this.decision.production.services.production[serviceId];
        }
      }

      this.currentStocks = {};
      for (let productId in this.state.production.stocks) {
        this.currentStocks[productId] =
          this.state.production.stocks[productId].amount;
      }

      this.checkErrors();
    },
    onDecisionUpdate() {
      this.$emit("decisionUpdate");
    },
    stopProduction(production) {
      production.production = 0;
      production.quality = 0;
      this.onDecisionUpdate();
    },
    checkErrors() {
      let tmp_errorMessages = [];
      let tmp_rules = {
        products: {
          production: [],
          quality: [],
        },
        services: {
          production: [],
          quality: [],
        },
      };
      let errorStatus = false;

      for (let config of Object.values(
        this.decision.production.products.production
      )) {
        if (isNaN(parseFloat(config.production))) {
          tmp_errorMessages.push(
            "La production de " + config.product.name + " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.products.production[config.product.uuid] = true;
        } else if (parseFloat(config.production) < 0) {
          tmp_errorMessages.push(
            "La production de " +
              config.product.name +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.products.production[config.product.uuid] = true;
        } else if (
          parseFloat(config.production) <
          parseFloat(this.session.mainParameters.production.min)
        ) {
          tmp_errorMessages.push(
            "La production de " +
              config.product.name +
              " doit être supérieur ou egal à " +
              parseFloat(this.session.mainParameters.production.min)
          );
          errorStatus = true;
          tmp_rules.products.production[config.product.uuid] = true;
        } else if (
          parseFloat(config.production) >
          parseFloat(this.session.mainParameters.production.max)
        ) {
          tmp_errorMessages.push(
            "La production de " +
              config.product.name +
              " doit être inférieur ou egal à " +
              parseFloat(this.session.mainParameters.production.max)
          );
          errorStatus = true;
          tmp_rules.products.production[config.product.uuid] = true;
        } else {
          tmp_rules.products.production[config.product.uuid] = false;
        }

        if (isNaN(parseFloat(config.quality))) {
          tmp_errorMessages.push(
            "Le budget RSE et qualité de " +
              config.product.name +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.products.quality[config.product.uuid] = true;
        } else if (parseFloat(config.quality) < 0) {
          tmp_errorMessages.push(
            "Le budget RSE et qualité de " +
              config.product.name +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.products.quality[config.product.uuid] = true;
        } else {
          tmp_rules.products.quality[config.product.uuid] = false;
        }
      }

      for (let config of Object.values(
        this.decision.production.services.production
      )) {
        if (isNaN(parseFloat(config.production))) {
          tmp_errorMessages.push(
            "La production de " + config.service.name + " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.services.production[config.service.uuid] = true;
        } else if (parseFloat(config.production) < 0) {
          tmp_errorMessages.push(
            "La production de " +
              config.service.name +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.services.production[config.service.uuid] = true;
        } else if (
          parseFloat(config.production) <
          parseFloat(this.session.mainParameters.production.min)
        ) {
          tmp_errorMessages.push(
            "La production de " +
              config.service.name +
              " doit être supérieur ou egal à " +
              parseFloat(this.session.mainParameters.production.min)
          );
          errorStatus = true;
          tmp_rules.services.production[config.service.uuid] = true;
        } else if (
          parseFloat(config.production) >
          parseFloat(this.session.mainParameters.production.max)
        ) {
          tmp_errorMessages.push(
            "La production de " +
              config.service.name +
              " doit être inférieur ou egal à " +
              parseFloat(this.session.mainParameters.production.max)
          );
          errorStatus = true;
          tmp_rules.services.production[config.service.uuid] = true;
        } else {
          tmp_rules.services.production[config.service.uuid] = false;
        }

        if (isNaN(parseFloat(config.quality))) {
          tmp_errorMessages.push(
            "Le budget RSE et qualité de " +
              config.service.name +
              " doit être un nombre"
          );
          errorStatus = true;
          tmp_rules.services.quality[config.service.uuid] = true;
        } else if (parseFloat(config.quality) < 0) {
          tmp_errorMessages.push(
            "Le budget RSE et qualité de " +
              config.service.name +
              " doit être supérieur ou egal à 0"
          );
          errorStatus = true;
          tmp_rules.services.quality[config.service.uuid] = true;
        } else {
          tmp_rules.services.quality[config.service.uuid] = false;
        }
      }

      this.errorMessages = tmp_errorMessages;
      this.errorRules = tmp_rules;
      this.$forceUpdate();
      return errorStatus;
    },
    getErrorMessages() {
      return this.errorMessages;
    },
  },
};
</script>
