import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "states"

class State{
    constructor(id, period, team, finances, machines, hr, production, scores){
        this.id = id
        this.period = period
        this.team = team
        this.finances = finances
        this.machines = machines
        this.hr = hr
        this.production = production        
        this.scores = scores
    }

    static async initializeOne(periodId, teamId){
        let tmp_finances = {
            capital: 0,
            netSituation: 0,
            treasury: 0,
            result: 0,
            reserves: 0,
            cumulatedAmortisation: 0,
            loanCapital: 0,
            taxCredits: [],
            workingCapital: 0,
            workingCapitalNeeds: 0,
            contractedLoans: [],
            supplierDebts: 0,
            customerDebts: 0,
        }

        let tmp_machines = {
            currentMachines: [],
            unlockedMachines: [],
        }

        let tmp_hr = {
            employeeAmount: 0,
            employeeSocialIndex : 0,
            workerAmount: 0,
            workerSocialIndex : 0,
            currentCvs: [],
            firedCvs: [],
        }

        let tmp_production = {
            unlockedProducts : [],
            unlockedServices : [],
            stocks : {},
            stocksValue : {},
        }

        let tmp_scores = {
            communication : {},
            sellingPower : {},
            quality : {},
            marketing : {},
            price : {},
        }

        let tmp_State = new State(null, periodId, teamId, tmp_finances, tmp_machines, tmp_hr, tmp_production, tmp_scores)
        return tmp_State
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let states = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_state = new State(doument.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
            states.push(tmp_state)
        })

        return states
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let state = new State(response.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)

        return state
    }

    static async getByPeriod(periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId))
        let response = await getDocs(documentQuery)

        let states = []

        response.forEach(document => {
            let data = document.data()
            let tmp_state = new State(document.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
            states.push(tmp_state)
        })

        return states
    } 

    static async getByPeriodAndTeam(periodId, teamId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId), where("team", "==", teamId))
        let response = await getDocs(documentQuery)

        let states = []

        response.forEach(document => {
            let data = document.data()
            let tmp_state = new State(document.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
            states.push(tmp_state)
        })

        return states
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let states = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_state = new State(document.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
                states.push(tmp_state)
            })
            
            if(callback != null){
                callback(states)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let state = new State(snapshot.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
            
            if(callback != null){
                callback(state)
            }
        })
        return unsub
    }

    static async listenByPeriod(periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let states = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_state =  new State(document.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
                states.push(tmp_state)
            })
            
            if(callback != null){
                callback(states)
            }
        })
        return unsub
    } 

    static listenByPeriodAndTeam(periodId, teamId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId), where("team", "==", teamId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let states = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_state = new State(document.id, data.period, data.team, data.finances, data.machines, data.hr, data.production, data.scores)
                states.push(tmp_state)
            })
            
            if(callback != null){
                callback(states)
            }
        })
        return unsub
    }
    
    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                period : this.period,
                team : this.team,
                finances : this.finances,
                machines : this.machines,
                hr : this.hr,
                production : this.production,
                scores : this.scores,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                period : this.period,
                team : this.team,
                finances : this.finances,
                machines : this.machines,
                hr : this.hr,
                production : this.production,
                scores : this.scores,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default State